import { IPaxesMaxRange } from "./fly-search.types";

export default abstract class SearchingData {
  readonly maxTotalPax: number = 9;
  readonly flightTypes: string[] = ["ROUNDTRIP", "ONEWAY", "MULTICITY"];
  readonly flightClassesList: Array<{ value: string, text: string }> = [
    {
      value: "ECONOMY",
      text: "Economy"
    },
    {
      value: "FIRST",
      text: "First"
    },
    {
      value: "BUSINESS",
      text: "Business"
    },
    {
      value: "PREMIUM_ECONOMY",
      text: "Premium economy"
    }
  ];

  readonly flightTypesCategory: string[] = [
    "REGULAR",
    "CHARTER"
  ];

  readonly flightTypesCategoryTemp: string[] = [
    "REGULAR",
    "CHARTER"
  ];

  pax: IPaxesMaxRange = {
    ADULT: [0, 9],
    CHILD: [0, 9],
    INFANT: [0, 9],
    SENIOR: [0, 9]
  };
}

import { Module } from "vuex";

import actionHelper from "utils/actionHelper";
import moment from "moment";

import FlySearchQuery, { FlySearchQueryFields } from "./types/fly-search-query.class";
import FlySearchResponse from "./types/fly-search-response.class";
import { IFlyProductSearchState, ISearchQueryBody } from "./types/fly-search.types";

// TODO: realize helper-module for action-mutation
const INPUT_QUERY = "INPUT_QUERY";
const SET_QUERY = "SET_QUERY";
const LOAD_QUERY = "LOAD_QUERY";
const INPUT_LAST_ENTERED_LETTER = "INPUT_LAST_ENTERED_LETTER";
const BEGIN_SEARCH = actionHelper("BEGIN_SEARCH");
const SET_FLIGHT_TYPES = actionHelper("SET_FLIGHT_TYPES");

export default ({ api }: any): Module<IFlyProductSearchState, RootState> => ({
  namespaced: true,

  state: {
    flightTypes: [],
    isError: false,
    isLoadingPending: false,
    lastEnteredLetter: {
      letter: null,
      timeOf: null
    },
    searchQuery: new FlySearchQuery({ ADULT: 1 }),
    searchResponse: new FlySearchResponse()
  },

  getters: {
    flightTypes: (state: IFlyProductSearchState) => state.flightTypes,
    isError: (state: IFlyProductSearchState) => state.isError,
    isLoadingPending: (state: IFlyProductSearchState) => state.isLoadingPending,
    lastEnteredLetter: (state: IFlyProductSearchState) => state.lastEnteredLetter,
    searchQuery: (state: IFlyProductSearchState) => state.searchQuery,
    searchResponse: (state: IFlyProductSearchState) => state.searchResponse
  },

  actions: {
    async beginSearch(
      { commit },
      {
        aia,
        query,
        websiteKey
      }: { aia: boolean; query: FlySearchQuery; websiteKey: string }
    ) {
      commit(BEGIN_SEARCH.INITIAL);
      const preQuery: ISearchQueryBody = {
        flightClasses: query.flightClasses,
        maxStops: query.maxStops,
        paxes: query.paxes,
        segments: query.codeSegments,
        flightTypes: query.flightTypesCategory
      };
      try {
        const beginSearchResult = await api.beginSearch(aia, preQuery, websiteKey);
        commit(
          BEGIN_SEARCH.SUCCEEDED,
          beginSearchResult
        );
        return beginSearchResult.data;
      } catch (e) {
        commit(BEGIN_SEARCH.FAILED, e);
      }
    },
    async getFlightTypes({ commit }, {
      uuid
    }: { uuid: string; }) {
      try {
        commit(SET_FLIGHT_TYPES.INITIAL);
        const response = await api.getFlightTypes(uuid);
        commit(SET_FLIGHT_TYPES.SUCCEEDED, response.data);
        return response.data;
      } catch (error) {
        commit(SET_FLIGHT_TYPES.FAILED, error);
      }
    },
    async setQuery({ commit }, query: FlySearchQuery) {
      commit(SET_QUERY, query);
    }
  },

  mutations: {
    [LOAD_QUERY](state, query: FlySearchQuery) {
      if (!query) {
        return;
      }
      const ignoreKeys = ["maxTotalPax", "flightTypes", "flightClasses"];
      (Object.keys(state.searchQuery) as Array<FlySearchQueryFields>)
        .filter((key) => !ignoreKeys.includes(key))
        .map((key) => {
          const value = query[key];
          if (value) {
            // @ts-ignore
            state.searchQuery[key] = query[key];
          }
        });
    },
    [INPUT_QUERY](state, data: { type: FlySearchQueryFields; value: any }) {
      if (!data) {
        state.searchQuery = new FlySearchQuery({ ADULT: 1 });
      } else {
        state.searchQuery[data.type] = data.value;
      }
    },
    [SET_QUERY](state, query: FlySearchQuery) {
      state.searchQuery = query;
    },
    [INPUT_LAST_ENTERED_LETTER](
      state,
      {
        letter,
        timeOf
      }: { letter: string; timeOf: moment.Moment }
    ) {
      state.lastEnteredLetter.letter = letter;
      state.lastEnteredLetter.timeOf = timeOf;
    },
    [BEGIN_SEARCH.INITIAL](state) {
      state.searchResponse = null;
      state.isLoadingPending = true;
    },
    [BEGIN_SEARCH.SUCCEEDED](state, search) {
      state.searchResponse = search.data;
      state.isLoadingPending = false;
    },
    [BEGIN_SEARCH.FAILED](state) {
      state.isLoadingPending = false;
    },
    [SET_FLIGHT_TYPES.INITIAL](state) {
      // initial
    },
    [SET_FLIGHT_TYPES.SUCCEEDED](state, flightTypes) {
      state.flightTypes = flightTypes;
    },
    [SET_FLIGHT_TYPES.FAILED](state, error) {
      console.error(error);
    }
  }
});

import SearchingData from "./fly-search-data.class";
import { ICodeSearchSegment, IPaxes, ISearchSegment } from "./fly-search.types";

export type FlySearchQueryFields = keyof FlySearchQuery
export type FlySearchQueryReadonlyFields = keyof SearchingData

export default class FlySearchQuery extends SearchingData {
  constructor(private _paxes: IPaxes) {
    super();
  }

  private _segments: ISearchSegment[] = [];

  get segments(): ISearchSegment[] {
    return this._segments;
  }

  set segments(segment: ISearchSegment[]) {
    this._segments = segment;
    this._codeSegments = this._getCodeSegments();
  }

  private _codeSegments: ICodeSearchSegment[] = [];

  get codeSegments(): ICodeSearchSegment[] {
    return this._codeSegments;
  }

  set codeSegments(codeSegment: ICodeSearchSegment[]) {
    this._codeSegments = codeSegment;
  }

  private _flightType: string = this._getFlightType();

  get flightType(): string {
    return this._flightType;
  }

  set flightType(fType: string) {
    this._flightType = fType;
  }

  private _flightClasses: string[] = ["ECONOMY", "PREMIUM_ECONOMY"];

  get flightClasses(): string[] {
    return this._flightClasses;
  }

  set flightClasses(fClass: string[]) {
    this._flightClasses = fClass;
  }

  private _maxStops = 10;

  get maxStops(): number {
    return this._maxStops;
  }

  set maxStops(stops: number) {
    this._maxStops = stops;
  }

  get paxes(): IPaxes {
    return this._paxes;
  }

  set paxes(pax: IPaxes) {
    this._paxes = pax;
  }

  static createFlySearchQueryFromObject(object: any): FlySearchQuery {
    return new FlySearchQuery(object.paxes);
  }

  public castFromObject(object: any) {
    Object.assign(this, object);
  }

  private _getFlightType(): string {
    let type: string = this.flightTypes[0];

    if (this._segments.length === 1) {
      type = this.flightTypes[1];
    } else if (this._segments.length > 2) {
      type = this.flightTypes[2];
    }

    return type;
  }

  private _getCodeSegments() {
    return this._segments.map((segment: ISearchSegment) => ({
      ...segment,
      fromAirport: segment.fromAirport ? segment.fromAirport.code : "",
      toAirport: segment.toAirport ? segment.toAirport.code : ""
    }));
  }
}
